import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Alert, Modal } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../css/Empresa.css";
import Config from "../Config";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import NavBar from "../components/Navbar.jsx";
import AuthUser from "../pageauth/AuthUser";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

moment.locale("es");
const localizer = momentLocalizer(moment);

const PanelAdmin = () => {
  const { getUser } = AuthUser();
  const [reservas, setReservas] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ventas, setVentas] = useState([]);
  const [reservasRegistradas, setReservasRegistradas] = useState(0);
  const [reservasNoRegistradas, setReservasNoRegistradas] = useState(0);
  const [totalGenerado, setTotalGenerado] = useState(0);
  const [totalVentasProductos, setTotalVentasProductos] = useState(0);
  const [filteredReservas, setFilteredReservas] = useState([]);
  const [filteredVentas, setFilteredVentas] = useState([]);
  const [reservasPorDia, setReservasPorDia] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedOption, setSelectedOption] = useState("hoy");

  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1); // Month is 0-based in moment.js, so we add 1

  useEffect(() => {
    const fetchData = async () => {
      try {
        await obtenerServicios();
        await obtenerHorarios();
        await obtenerReservas();
        await obtenerProductos();
        await fetchVentas();
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (reservas.length > 0) {
      filtrarPorOpcionReservas();
    }
    if (ventas.length > 0) {
      filtrarPorOpcionVentas();
    }
  }, [selectedOption, selectedYear, selectedMonth, reservas, ventas]);

  const obtenerServicios = async () => {
    try {
      const response = await Config.getServicesByEmpresa(getUser().id);
      setServicios(response.data);
    } catch (error) {
      console.error("Error al obtener servicios:", error);
    }
  };

  const obtenerHorarios = async () => {
    try {
      const response = await Config.getIntervalosEmpresa(getUser().id);
      setHorarios(response.data.data);
    } catch (error) {
      console.error("Error al obtener horarios:", error);
    }
  };

  const obtenerReservas = async () => {
    try {
      const userId = getUser().id;
      const [response1, response2] = await Promise.all([
        Config.getReservasEmpresa(userId),
        Config.getReservasSinUsuarioEmpresa(userId),
      ]);

      const reservasRegistradasData = response1.data.data.filter(
        (reserva) => reserva.estado === "reservado"
      );
      const reservasNoRegistradasData = response2.data.data.filter(
        (reserva) => reserva.estado === "reservado"
      );

      const allReservas = [
        ...reservasRegistradasData.map((r) => ({ ...r, isRegistered: true })),
        ...reservasNoRegistradasData.map((r) => ({ ...r, isRegistered: false })),
      ];

      setReservas(allReservas);
      setReservasRegistradas(reservasRegistradasData.length);
      setReservasNoRegistradas(reservasNoRegistradasData.length);
    } catch (error) {
      console.error("Error al obtener reservas:", error);
    }
  };

  const obtenerProductos = async () => {
    try {
      const response = await Config.getProductosEmpresa(getUser().id);
      setProductos(response.data);
    } catch (error) {
      console.error("Error al obtener productos:", error);
    }
  };

  const fetchVentas = async () => {
    try {
      const response = await axios.get(`${Config.url()}/empresa/${getUser().id}/ventas`);
      setVentas(response.data.ventas);
    } catch (error) {
      console.error("Error al obtener las ventas:", error);
    }
  };

  const calcularFechaFin = (fecha, hora, duracion) => {
    const fechaInicio = new Date(fecha + "T" + hora);
    const fechaFin = new Date(fechaInicio.getTime() + duracion * 60000);
    return fechaFin;
  };

  // Function to filter `reservas` by the selected time period
  const filtrarPorOpcionReservas = () => {
    let startDate, endDate;

    switch (selectedOption) {
      case "hoy":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "semana":
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");
        break;
      case "mes":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;
      case "año":
        startDate = moment().startOf("year");
        endDate = moment().endOf("year");
        break;
      case "anioMes":
        startDate = moment().year(selectedYear).month(selectedMonth - 1).startOf("month");
        endDate = moment().year(selectedYear).month(selectedMonth - 1).endOf("month");
        break;
      default:
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
    }

    const reservasFiltradas = reservas.filter((reserva) =>
      moment(reserva.fecha).isBetween(startDate, endDate, null, "[]")
    );

    setFilteredReservas(reservasFiltradas);

    const total = reservasFiltradas.reduce(
      (sum, reserva) => sum + (parseFloat(reserva.precio) || 0), // Ensure the price is parsed to a number or set to 0
      0
    );

    setTotalGenerado(total);

    const registeredCount = reservasFiltradas.filter(
      (reserva) => reserva.isRegistered
    ).length;
    const unregisteredCount = reservasFiltradas.filter(
      (reserva) => !reserva.isRegistered
    ).length;

    setReservasRegistradas(registeredCount);
    setReservasNoRegistradas(unregisteredCount);

    const reservasPorDiaData = Array(7).fill(0); // Initialize array for days of the week
    reservasFiltradas.forEach((reserva) => {
      const dia = moment(reserva.fecha).day(); // Get the day of the week (0: Sunday, 6: Saturday)
      reservasPorDiaData[dia]++;
    });
    setReservasPorDia(reservasPorDiaData);
  };

  // Function to filter `ventas` by the selected time period
  const filtrarPorOpcionVentas = () => {
    let startDate, endDate;

    switch (selectedOption) {
      case "hoy":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "semana":
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");
        break;
      case "mes":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;
      case "año":
        startDate = moment().startOf("year");
        endDate = moment().endOf("year");
        break;
      case "anioMes":
        startDate = moment().year(selectedYear).month(selectedMonth - 1).startOf("month");
        endDate = moment().year(selectedYear).month(selectedMonth - 1).endOf("month");
        break;
      default:
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
    }

    const ventasFiltradas = ventas.filter((venta) =>
      moment(venta.created_at).isBetween(startDate, endDate, null, "[]")
    );

    setFilteredVentas(ventasFiltradas);

    const totalVentas = ventasFiltradas.reduce(
      (total, venta) => total + (parseFloat(venta.total) || 0),
      0
    );

    setTotalVentasProductos(totalVentas);
  };

  const eventos = filteredReservas.map((reserva) => ({
    servicios: reserva.servicios,
    id: reserva.id,
    title: `${reserva.cliente?.name || reserva.nombre_cliente}`, // Reserva para
    start: new Date(reserva.fecha + "T" + reserva.hora),
    end: calcularFechaFin(reserva.fecha, reserva.hora, reserva.duracion),
  }));

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowEventModal(true);
  };

  const handleCloseModal = () => {
    setShowEventModal(false);
    setSelectedEvent(null);
  };

  const renderYearOptions = () => {
    const currentYear = moment().year();
    const years = [];
    for (let year = currentYear - 10; year <= currentYear + 10; year++) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const renderMonthOptions = () => {
    return moment.months().map((month, index) => (
      <option key={index} value={index + 1}>
        {month}
      </option>
    ));
  };

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <>
      <NavBar />
      <Container className="mt-3">
        {!servicios.length && (
          <Alert
            variant="info"
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              Para que los clientes puedan realizar reservas, debes registrar tu
              primer servicio.
            </span>
            <Button variant="primary" size="sm" href="/servicios">
              Registrar Servicio
            </Button>
          </Alert>
        )}
        {!horarios.length && (
          <Alert
            variant="info"
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              Para que los clientes puedan realizar reservas, debes registrar tu
              primer horario.
            </span>
            <Button variant="primary" size="sm" href="/intervalos">
              Registrar Horario
            </Button>
          </Alert>
        )}

        <Col xs={12} md={4} className="text-center">
          <h2>Resumen</h2>
        </Col>
        <Row className="align-items-center">
          <Col xs={12} md={4} className="text-center cardAdmin">
            <h3>Selecciona un periodo</h3>
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="form-select"
            >
              <option value="hoy">Hoy</option>
              <option value="semana">Esta Semana</option>
              <option value="mes">Este Mes</option>
              <option value="año">Este Año</option>
              <option value="anioMes">Año y Mes</option> {/* New option */}
            </select>
            {selectedOption === "anioMes" && (
              <div className="mt-3">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="form-select mb-2"
                >
                  {renderYearOptions()}
                </select>
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="form-select"
                >
                  {renderMonthOptions()}
                </select>
              </div>
            )}
          </Col>
          <Col xs={12} md={2} className="text-center cardAdmin">
            <h3>Reservas</h3>
            <h4>{filteredReservas.length}</h4>
          </Col>
          <Col xs={12} md={3} className="text-center cardAdmin">
            <h3>Total Generado</h3>
            <h4>${Number(totalGenerado).toFixed(2)}</h4>
          </Col>
        </Row>
      </Container>

      <Container className="mt-3 cardPlanes">
        <h2>Reservas Filtradas</h2>
        <Calendar
          localizer={localizer}
          events={eventos}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          defaultView="day"
          views={["day", "week", "month"]}
          selectable={true}
          min={new Date(moment().startOf("day").hour(1))}
          max={new Date(moment().startOf("day").hour(23))}
          scrollToTime={new Date(moment().startOf("day").hour(8))}
          onSelectEvent={handleEventClick}
          culture="es"
        />
      </Container>

      <Modal show={showEventModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la Reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <>
              <p>
                <strong>Cliente:</strong> {selectedEvent.title}
              </p>
              <p>
                <strong>Servicios:</strong>{" "}
                {selectedEvent.servicios
                  .map((servicio) => servicio.nombre)
                  .join(", ")}
              </p>
              <p>
                <strong>Fecha:</strong>{" "}
                {moment(selectedEvent.start).format("LL")}
              </p>
              <p>
                <strong>Hora de Inicio:</strong>{" "}
                {moment(selectedEvent.start).format("LT")}
              </p>
              <p>
                <strong>Hora de Fin:</strong>{" "}
                {moment(selectedEvent.end).format("LT")}
              </p>
              <p>
                <strong>Duración:</strong>{" "}
                {moment
                  .duration(moment(selectedEvent.end).diff(moment(selectedEvent.start)))
                  .asMinutes()}{" "}
                minutos
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Container className="mt-5">
        <Row>
          <Col xs={12} md={3} className="text-center cardAdmin">
            <h3>Fuentes de reservas</h3>
            <Doughnut
              data={{
                labels: ["Usuarios registrados", "Usuarios no registrados"],
                datasets: [
                  {
                    data: [reservasRegistradas, reservasNoRegistradas],
                    backgroundColor: [
                      "rgba(75, 192, 192, 0.8)",
                      "rgba(255, 159, 64, 0.8)",
                    ],
                  },
                ],
              }}
            />
          </Col>
          <Col xs={12} md={3} className="text-center cardAdmin">
            <h3>Ventas facturadas</h3>
            <p>Gráfica Servicios, Productos</p>
            <Bar
              data={{
                labels: ["Servicios", "Productos"],
                datasets: [
                  {
                    label: "Servicios",
                    data: [totalGenerado],
                    backgroundColor: "rgba(75, 192, 192, 0.8)",
                    borderColor: "rgba(75, 192, 192, 1)",
                    borderWidth: 1,
                  },
                  {
                    label: "Productos",
                    data: [totalVentasProductos],
                    backgroundColor: "rgba(255, 159, 64, 0.8)",
                    borderColor: "rgba(255, 159, 64, 1)",
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </Col>
          <Col xs={12} md={3} className="text-center cardAdmin">
            <h3>Factor de ocupación</h3>
            <p>De Lunes a domingo</p>
            <Line
              data={{
                labels: [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sábado",
                  "Domingo",
                ],
                datasets: [
                  {
                    label: "Reservas",
                    data: reservasPorDia
                      .slice(1)
                      .concat(reservasPorDia[0]), // Reorder to start with Monday
                    backgroundColor: "rgba(75, 192, 192, 0.8)",
                    borderColor: "rgba(75, 192, 192, 1)",
                    borderWidth: 2,
                  },
                ],
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PanelAdmin
